<template>
  <div class="interface">
    <b-card class="login-card">
      <div class="logo">
        <!-- <img src="../assets/images/logo-method.png" /> -->
      </div>
      <div>
        <span>Entre com sua nova senha</span>
        <b-form @submit.prevent="resetPassword">
          <div class="h-separator">
            <label for="password" class="title">Nova Senha *</label>
            <span></span>
          </div>
          <b-form-input id="password" v-model="password" type="password" placeholder="Senha" required></b-form-input>
          <b-button type="submit">Entrar</b-button>
        </b-form>
      </div>
    </b-card>
  </div>
</template>

<style lang="stylus" scoped>
@import '../style/colors.styl';
@import '../style/fonts.styl';

.interface
  min-height 100%
  min-height 100vh
  display flex
  align-items center
  background-image: url('../assets/images/tabletalks_bj.jpg')
  background-size     cover
  background-repeat   no-repeat
  background-position left
  background-color info
  height 100%

.login-card
  position absolute
  right 5%
  width 350px
  min-height 52% // old support
  min-height 52vh
  border-radius 0.5rem
  margin 0 auto
  display flex
  background light

@media (max-width: 556px)
    .login-card
        position unset
        margin 0 auto
        width: 90%
        min-height 75vh

.card-body, .card-body > div
  display grid
  justify-content space-around

.logo
  margin-bottom 5px
  background-color #000819
  border-radius: 10px
  padding 5%

  img
    width 250px
.h-separator
    display flex
    .title
        padding 0px 10px
        font-size 14px
        color light
        font-weight bold
        border-radius 1rem
        background primary
    span
        align-self center
    span:last-child
        border 1px dashed primary
        height 1px
    span:last-child
        flex-grow 1

form
  width 100%

  *
    font-family Manrope

  .title
    text-align center
    color primary

  label
    color light
    padding 0 0.5rem
    margin 8px 0
    border-radius 1rem
    font-weight bold
    background primary

  input, button
    height calc(1.5em + 0.75rem + 2px)
    padding 0.4rem 1rem

  input
    margin-bottom 18px
    border-radius 0.5rem
    background primaryDark
    color secondary

  button
    width 100%
    margin 10px 0 0 0
    background primary

    &:hover
      background: secondary
</style>

<script>
import { request } from '@/services'
import { toast, errorFormater } from '@/utils'

export default {
  data: () => ({
    password: ''
  }),
  methods: {
    resetPassword: function () {
      request
        .post('/reset_password', {
          password: this.password,
          password_confirmation: this.password
        })
        .then(
          (response) => {
            if (response.status >= 200 && response.status < 300) {
              this.$router.push({ path: '/password-reseted' })
            }
          },
          (error) => {
            toast(this, 'danger', 'Erro', errorFormater(error))
            return Promise.reject(error)
          }
        )
    }
  }
}
</script>
